
export default {
  name: 'ContentSection',
  props: {
    layout: {
      type: String,
      required: true,
    },
  }
}
