import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "centered-layout" }
const _hoisted_3 = { class: "text-xl hero__title" }
const _hoisted_4 = { class: "text-m hero__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t("hero_title")), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t("hero_description")), 1),
      _createVNode(_component_ButtonComponent, {
        class: "hero__button",
        hierarchy: "primary",
        link: "https://support.phrase.com/hc/en-us/articles/5784095916188"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("hero_link_docs")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ButtonComponent, {
        class: "hero__button",
        hierarchy: "secondary",
        link: "https://github.com/phrase/phrase-demo-app"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("hero_link_code")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}