import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = { class: "text-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("figure", null, [
    _createElementVNode("img", {
      alt: `Logo of ${$props.name}`,
      src: require(`@/assets/clients/${$props.link}`)
    }, null, 8, _hoisted_1),
    _createElementVNode("figcaption", _hoisted_2, _toDisplayString($props.name), 1)
  ]))
}