import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "client-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Client = _resolveComponent("Client")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.clients, (name, link) => {
      return (_openBlock(), _createElementBlock("li", {
        key: name,
        class: "client-list__item"
      }, [
        _createVNode(_component_Client, {
          name: name,
          link: link
        }, null, 8, ["name", "link"])
      ]))
    }), 128))
  ]))
}