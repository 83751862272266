

export default {
  name: 'ClientComponent',
  props: {
    link: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    }
  }
}
