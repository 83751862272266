

import Client from '@/components/Client.vue'

export default {
  name: 'ClientList',
  components: {
    Client
  },
  data () {
    return {
      clients: {
        'rails.png': 'Ruby on Rails',
        'angular.png': 'Angular\xa0JS, Angular\xa02+',
        'django.png': 'Django',
        'spring.png': 'Spring Framework',
        'react.png': 'React',
        'vue.png': 'Vue.js',
        'symfony.png': 'Symfony\xa02.x, Symfony\xa03.x'
      }
    }
  }
}
