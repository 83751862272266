
import NavBar from './components/NavBar.vue'
import HeroComponent from './components/HeroComponent.vue'
import ContentSection from './components/ContentSection.vue'
import HeadingWithText from '@/components/HeadingWithText.vue'
import ClientList from '@/components/ClientList.vue'
import { useTranslate } from './i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    ClientList,
    HeadingWithText,
    NavBar,
    HeroComponent,
    ContentSection
  },
  setup () {
    return {
      ...useTranslate()
    }
  }
})
